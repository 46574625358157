<div class="card">
  <div class="gauge-container">
    <div class="gauge">
      <div class="gauge-fill" [ngStyle]="{ transform: rotation }"></div>
      <div class="gauge-cover">
        <span class="percentage">{{ percentage() }}%</span>
        <span class="responses">{{ responses() }}</span>
      </div>
    </div>
  </div>
</div>
