import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from '../shared/components/header/header.component';

@Component({
  selector: 'app-main',
  standalone: true,
  imports: [RouterModule, HeaderComponent],
  template: `
    <app-header />
    <div class="content">
      <router-outlet />
    </div>
  `,
  styles: [
    `
      .content {
        min-height: 100%;
        min-width: 1024px;
        padding: 8rem 4rem;
        background: var(--s-high-medium);
      }
    `,
  ],
})
export class MainComponent {}
