import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { CloudData } from 'angular-tag-cloud-module';
import { WordCloudComponent } from '../../shared/components/word-cloud/word-cloud.component';
import { WordTableComponent } from '../../shared/components/word-table/word-table.component';
import { IReportFilter } from '../../utils/interfaces/report-filter';
import { PrintDocument } from '../print-document';

@Component({
  selector: 'app-print-word-cloud',
  standalone: true,
  imports: [CommonModule, WordCloudComponent, WordTableComponent],
  templateUrl: './print-word-cloud.component.html',
  styleUrl: './print-word-cloud.component.scss',
})
export class PrintWordCloudComponent
  extends PrintDocument
  implements OnInit, AfterViewInit
{
  private readonly titleService = inject(Title);

  filter!: IReportFilter;
  data: CloudData[] = [];

  count = 0;
  title = '';

  ngOnInit(): void {
    this.handleData();
  }

  ngAfterViewInit(): void {
    this.reportReadyData();
  }

  protected override handleData(): void {
    const { data, filter, count, title } =
      this.activatedRoute.snapshot.data['data'];

    this.data = data;
    this.filter = filter;
    this.count = count;
    this.title = title;

    this.titleService.setTitle(
      this.changeTitlePage(
        'Relatório wordcloud',
        this.filter.startDate,
        this.filter.finishDate
      )
    );
  }

  protected override reportReadyData(): void {
    setTimeout(() => {
      this.printDocumentService.onDataReady();
    }, 0);
  }
}
