import { CommonModule } from '@angular/common';
import { Component, effect, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { PrintDocumentService } from '../../../../print-documents/print-document.service';
import { CustomBarChartComponent } from '../../../../shared/components/custom-bar-chart/custom-bar-chart.component';
import {
  DocumentTypeEnum,
  ExportToPdfService,
} from '../../../../shared/services/export-to-pdf.service';
import { ReportFilterService } from '../../../../shared/services/report-filter.service';
import { ReportService } from '../../../../shared/services/report.service';
import { ChartTypeColorsEnum } from '../../../../utils/enums/chart-colors.enum';
import { IReportFilter } from '../../../../utils/interfaces/report-filter';
import { ISummaryReport } from '../../../../utils/interfaces/summary-report';
import { LoadingService } from './../../../../shared/services/loading.service';

@Component({
  selector: 'app-summary',
  standalone: true,
  imports: [CommonModule, CustomBarChartComponent],
  templateUrl: './summary.component.html',
  styleUrl: './summary.component.scss',
})
export class SummaryComponent implements OnDestroy {
  chartColorsEnum = ChartTypeColorsEnum;
  private readonly unsubscribe$ = new Subject();

  data!: ISummaryReport;
  filter: IReportFilter | null = null;

  constructor(
    private readonly reportFilterService: ReportFilterService,
    private readonly reportService: ReportService,
    private readonly loadingService: LoadingService,
    private readonly exportToPdfService: ExportToPdfService,
    private readonly printDocumentService: PrintDocumentService
  ) {
    effect(
      () => {
        this.filter = reportFilterService.getFilter();
        this.getReportData();
      },
      {
        allowSignalWrites: true,
      }
    );

    effect(
      () => {
        this.printDocument(exportToPdfService.getDocumentType());
      },
      {
        allowSignalWrites: true,
      }
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  getReportData(): void {
    if (!this.filter) return;

    setTimeout(() => {
      this.loadingService.showLoading();
    }, 0);

    this.reportService
      .getSummaryReport(this.filter)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.data = res.data;
        this.loadingService.closeLoading();
      });
  }

  printDocument(document: DocumentTypeEnum | null): void {
    if (!document || !this.data) return;

    this.printDocumentService.printDocument(DocumentTypeEnum.summary, {
      data: this.data,
      filter: this.filter,
    });
    this.exportToPdfService.generatePDF(null);
  }
}
