<table>
  <thead>
    <tr>
      <th class="first">Top 10 palavras</th>
      <th class="last">Frequência</th>
    </tr>
  </thead>
  <tbody>
    @for (item of data(); track $index) { @if ($index + 1 <= 10) {
    <tr (click)="onClickEvent.emit(item)">
      <td>{{ $index + 1 }}. {{ item.text }}</td>
      <td>
        {{ item.weight }} ({{ ((item.weight / count()) * 100).toFixed(2) }}%)
      </td>
    </tr>
    } }
  </tbody>
</table>
