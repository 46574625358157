import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { IMeter } from '../../../utils/interfaces/meter';

@Component({
  selector: 'app-cards-weight',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cards-weight.component.html',
  styleUrl: './cards-weight.component.scss',
})
export class CardsWeightComponent {
  dataMeter = input.required<IMeter>();
}
