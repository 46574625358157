<p>
  Unidades do filtro: @for (branch of filter.branchesName; track $index) {
  {{ branch }}@if (filter.branchesName && $index < filter.branchesName.length -
  1) {, } }
</p>

<p>
  Período do filtro:
  {{ filter.startDate | date : "dd/MM/yyyy" }} à
  {{ filter.finishDate | date : "dd/MM/yyyy" }}
</p>

<app-bar-chart [data]="data" text="Relatório de engajamento" />
