<p>
  Unidades do filtro: @for (branch of filter.branchesName; track $index) {
  {{ branch }}@if (filter.branchesName && $index < filter.branchesName.length -
  1) {, } }
</p>

<p>
  Período do filtro:
  {{ filter.startDate | date : "dd/MM/yyyy" }} à
  {{ filter.finishDate | date : "dd/MM/yyyy" }}
</p>

@if (data) {

<div class="card">
  @if(data.dataEvolution) {
  <p class="title">{{ data.dataEvolution.title }}</p>
  }

  <hr />

  <div class="content">
    <app-cards-weight [dataMeter]="data.dataMeter" />
    <div class="gauge">
      <app-gauge-chart
        [percentage]="data.dataMeter.measurmentPercentage"
        responses="{{ data.dataMeter.responseCount }} respostas {{
          filter!.startDate | date : 'MMM yy'
        }} - {{ filter!.finishDate | date : 'MMM yy' }}"
      />
    </div>
  </div>
</div>

} @if(data.dataEvolution) {
<app-line-chart
  [labels]="data.dataEvolution.labels"
  [datasets]="data.dataEvolution"
/>
}
