import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { CardsWeightComponent } from '../../shared/components/cards-weight/cards-weight.component';
import { GaugeChartComponent } from '../../shared/components/gauge-chart/gauge-chart.component';
import { LineChartComponent } from '../../shared/components/line-chart/line-chart.component';
import { IEvolutionReport } from '../../utils/interfaces/evolution-report';
import { IMeter } from '../../utils/interfaces/meter';
import { IReportFilter } from '../../utils/interfaces/report-filter';
import { PrintDocument } from '../print-document';

@Component({
  selector: 'app-print-meter',
  standalone: true,
  imports: [
    CommonModule,
    CardsWeightComponent,
    GaugeChartComponent,
    LineChartComponent,
  ],
  templateUrl: './print-meter.component.html',
  styleUrl: './print-meter.component.scss',
})
export class PrintMeterComponent
  extends PrintDocument
  implements OnInit, AfterViewInit
{
  private readonly titleService = inject(Title);
  data!: {
    dataEvolution: IEvolutionReport;
    dataMeter: IMeter;
  };
  filter!: IReportFilter;

  ngOnInit(): void {
    this.handleData();
  }

  ngAfterViewInit(): void {
    this.reportReadyData();
  }

  protected override handleData(): void {
    const { data, filter } = this.activatedRoute.snapshot.data['data'];

    this.data = data;
    this.filter = filter;

    this.titleService.setTitle(
      this.changeTitlePage(
        'Relatório de medidor',
        this.filter.startDate,
        this.filter.finishDate
      )
    );
  }

  protected override reportReadyData(): void {
    setTimeout(() => {
      this.printDocumentService.onDataReady();
    }, 1000);
  }
}
