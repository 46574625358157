import { CommonModule } from '@angular/common';
import { Component, input, OnChanges, output } from '@angular/core';

import {
  CloudData,
  CloudOptions,
  TagCloudComponent,
} from 'angular-tag-cloud-module';

@Component({
  selector: 'app-word-cloud',
  standalone: true,
  imports: [CommonModule, TagCloudComponent],
  templateUrl: './word-cloud.component.html',
  styleUrl: './word-cloud.component.scss',
})
export class WordCloudComponent implements OnChanges {
  options: CloudOptions = {
    width: 900,
    height: 300,
    overflow: false,
    zoomOnHover: { scale: 1.5, transitionTime: 1, delay: 0.1 },
    step: 1,
    realignOnResize: true,
    strict: true,
    randomizeAngle: true,
    font: 'italic bold 12px "Indie Flower", cursive',
  };

  data = input.required<CloudData[]>();
  onClickEvent = output<CloudData>();

  cloudData: CloudData[] = [];

  ngOnChanges(): void {
    this.cloudData = [...this.normalizarWeights(this.data())];
  }

  normalizarWeights(array: CloudData[]): CloudData[] {
    // Encontra o valor mínimo e máximo de weight no array
    const minWeight = Math.min(...array.map((item) => item.weight));
    const maxWeight = Math.max(...array.map((item) => item.weight));

    // Normaliza os weights para o intervalo [1, 10]
    return array.map((item) => {
      const normalizedWeight =
        ((item.weight - minWeight) / (maxWeight - minWeight)) * 9 + 1; // Ajusta para o intervalo [1, 10]

      // Arredonda para o inteiro mais próximo
      const roundedWeight = Math.round(normalizedWeight);

      return { text: item.text, weight: roundedWeight };
    });
  }
}
