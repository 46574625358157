@if (dataMeter) {

<div class="card">
  @if(dataEvolution) {
  <p class="title">{{ dataEvolution.title }}</p>
  }

  <hr />

  <div class="content">
    <app-cards-weight [dataMeter]="dataMeter" />
    <div class="gauge">
      <app-gauge-chart
        [percentage]="dataMeter.measurmentPercentage"
        responses="{{ dataMeter.responseCount }} respostas {{
          filter!.startDate | date : 'MMM yy'
        }} - {{ filter!.finishDate | date : 'MMM yy' }}"
      />
    </div>
  </div>
</div>

} @if(dataEvolution) {
<app-line-chart [labels]="dataEvolution.labels" [datasets]="dataEvolution" />
} @if (!dataMeter && !dataEvolution) {
<div class="empty">
  <p>Não há dados para exibir</p>
</div>
}
