import { CommonModule } from '@angular/common';
import { Component, effect, OnDestroy, OnInit } from '@angular/core';

import { Subject, takeUntil } from 'rxjs';
import { PrintDocumentService } from '../../../../print-documents/print-document.service';
import { CardsWeightComponent } from '../../../../shared/components/cards-weight/cards-weight.component';
import { GaugeChartComponent } from '../../../../shared/components/gauge-chart/gauge-chart.component';
import { LineChartComponent } from '../../../../shared/components/line-chart/line-chart.component';
import {
  DocumentTypeEnum,
  ExportToPdfService,
} from '../../../../shared/services/export-to-pdf.service';
import { LoadingService } from '../../../../shared/services/loading.service';
import { ReportFilterService } from '../../../../shared/services/report-filter.service';
import { ReportService } from '../../../../shared/services/report.service';
import { IEvolutionReport } from '../../../../utils/interfaces/evolution-report';
import { IMeter } from '../../../../utils/interfaces/meter';
import { IReportFilter } from '../../../../utils/interfaces/report-filter';

@Component({
  selector: 'app-meter',
  standalone: true,
  imports: [
    CommonModule,
    CardsWeightComponent,
    GaugeChartComponent,
    LineChartComponent,
  ],
  templateUrl: './meter.component.html',
  styleUrl: './meter.component.scss',
})
export class MeterComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$ = new Subject();

  dataEvolution!: IEvolutionReport;
  dataMeter!: IMeter;
  filter: IReportFilter | null = null;
  initialDate!: string;
  finalDate!: string;

  constructor(
    private readonly reportFilterService: ReportFilterService,
    private readonly reportService: ReportService,
    private readonly loadingService: LoadingService,
    private readonly exportToPdfService: ExportToPdfService,
    private readonly printDocumentService: PrintDocumentService
  ) {
    effect(
      () => {
        this.printDocument(exportToPdfService.getDocumentType());
      },
      {
        allowSignalWrites: true,
      }
    );
    effect(
      () => {
        this.filter = reportFilterService.getFilter();
        this.getEvolutionReport();
        this.getMeterReport();
      },
      {
        allowSignalWrites: true,
      }
    );
  }

  ngOnInit(): void {
    this.initialDate =
      new Date(new Date().setDate(new Date().getDate() - 335))
        .toISOString()
        .split('T')[0] + 'T00:00:00.000Z';

    this.finalDate = new Date().toISOString().split('T')[0] + 'T00:00:00.000Z';
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  getEvolutionReport(): void {
    if (!this.filter) return;

    setTimeout(() => {
      this.loadingService.showLoading();
    }, 0);

    this.reportService
      .getEvolutionReport({
        ...this.filter,
        startDate: this.initialDate,
        finishDate: this.finalDate,
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        if (res.data.length) {
          this.dataEvolution = res.data[0];
        }

        setTimeout(() => {
          this.loadingService.closeLoading();
        }, 750);
      });
  }

  getMeterReport(): void {
    if (!this.filter) return;

    setTimeout(() => {
      this.loadingService.showLoading();
    }, 0);

    this.reportService
      .getMeterReport(this.filter)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.dataMeter = res.data;

        setTimeout(() => {
          this.loadingService.closeLoading();
        }, 750);
      });
  }

  printDocument(document: DocumentTypeEnum | null): void {
    if (!document || !this.dataEvolution || !this.dataMeter) {
      this.exportToPdfService.generatePDF(null);
      return;
    }

    this.printDocumentService.printDocument(DocumentTypeEnum.meter, {
      data: {
        dataEvolution: this.dataEvolution,
        dataMeter: this.dataMeter,
        initialDate: this.initialDate,
        finalDate: this.finalDate,
      },
      filter: this.filter,
    });
    this.exportToPdfService.generatePDF(null);
  }
}
