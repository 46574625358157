<angular-tag-cloud
  [data]="cloudData"
  [width]="options.width"
  [height]="options.height"
  [overflow]="options.overflow"
  [zoomOnHover]="options.zoomOnHover"
  [step]="options.step"
  [realignOnResize]="options.realignOnResize"
  [strict]="options.strict"
  [randomizeAngle]="options.randomizeAngle"
  [font]="options.font"
  (clicked)="onClickEvent.emit($event)"
>
</angular-tag-cloud>
