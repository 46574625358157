import { CommonModule } from '@angular/common';
import { Component, effect, OnDestroy, OnInit } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { forkJoin, Observable, Subject, takeUntil } from 'rxjs';

import { ActivatedRoute } from '@angular/router';
import {
  IPaginationValue,
  PaginationComponent,
} from '../../../../shared/components/pagination/pagination.component';
import { LoadingService } from '../../../../shared/services/loading.service';
import { ReportFilterService } from '../../../../shared/services/report-filter.service';
import { ReportService } from '../../../../shared/services/report.service';
import { IFeedbackReport } from '../../../../utils/interfaces/feedback-report';
import { IPage } from '../../../../utils/interfaces/poll';
import {
  IFeedbackFilter,
  IReportFilter,
} from '../../../../utils/interfaces/report-filter';
import { IResponse } from '../../../../utils/interfaces/response';

@Component({
  selector: 'app-feedback',
  standalone: true,
  imports: [CommonModule, MatExpansionModule, PaginationComponent],
  templateUrl: './feedback.component.html',
  styleUrl: './feedback.component.scss',
})
export class FeedbackComponent implements OnInit, OnDestroy {
  countPoll = 0;
  filter: IReportFilter | null = null;
  private readonly unsubscribe$ = new Subject();
  data!: IFeedbackReport[];

  pagination: IPaginationValue = {
    page: 1,
    take: 50,
  };

  questiosIds: string[] = [];

  constructor(
    private readonly reportFilterService: ReportFilterService,
    private readonly reportService: ReportService,
    private readonly loadingService: LoadingService,
    private readonly activatedRoute: ActivatedRoute
  ) {
    effect(
      () => {
        this.filter = reportFilterService.getFilter();
        this.getReportData();
      },
      {
        allowSignalWrites: true,
      }
    );
  }

  ngOnInit(): void {
    const questions: IPage[] =
      this.activatedRoute.snapshot.parent?.data['poll']['data']['pages'];

    questions.forEach((page) => {
      page.questions.forEach((question) => {
        if (question.type === 3) this.questiosIds.push(question.id!);
      });
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  getReportData(): void {
    if (!this.filter) return;

    setTimeout(() => {
      this.loadingService.showLoading();
    }, 0);

    let OBS: Observable<IResponse<IFeedbackReport>>[] = [];

    this.questiosIds.forEach((id) => {
      const newFilter: IFeedbackFilter = {
        ...this.filter!,
        questionId: id,
        take: 50,
        skip: 1,
      };
      OBS.push(this.reportService.getFeedbackReport(newFilter));
    });

    forkJoin(OBS)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.data = [];

        res.forEach((r) => {
          r.data.count = r.count;
          this.data.push(r.data);
        });

        setTimeout(() => {
          this.loadingService.closeLoading();
        }, 0);
      });
  }

  onPaginationChange(
    event: IPaginationValue,
    questionId: string,
    index: number
  ): void {
    this.loadingService.showLoading();
    this.pagination = event;
    const newFilter: IFeedbackFilter = {
      ...this.filter!,
      questionId: questionId,
      take: event.take,
      skip: event.page,
    };

    this.reportService
      .getFeedbackReport(newFilter)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        res.data.count = res.count;
        this.data[index] = res.data;
        this.loadingService.closeLoading();
      });
  }
}
