@for (response of data; track $index) {
<div class="accordion">
  <mat-accordion>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>{{ response.title }}</mat-panel-title>
      </mat-expansion-panel-header>
      <hr />

      <div class="content-accordion">
        @for (res of response.responsesOpens; track res) {

        <div class="data">
          <div class="branch">{{ res.branchCode }} {{ res.branchName }}</div>
          <div class="date">
            {{ res.createdAt | date : "dd/MM/yyyy HH:mm:ss" }}
          </div>
          <div class="satisfaction">{{ res.answerMainQuestion }}</div>
          <div class="text">
            {{ res.answerOpen || "--" }}
          </div>
        </div>
        }
      </div>

      <app-pagination
        [total]="response.count || 0"
        [value]="{ page: response.skip, take: response.take }"
        [showRowsSelect]="true"
        (onPaginationChange)="
          onPaginationChange($event, response.questionId, $index)
        "
      />
    </mat-expansion-panel>
  </mat-accordion>
</div>
} @empty {
<div class="empty">
  <p>Não há dados para exibir</p>
</div>
}
