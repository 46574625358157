export const SingleColor = '#4572a7';
export enum EColorsWeight {
  '#27ae60' = 4,
  '#2ecc71' = 3,
  '#f1c40f' = 2,
  '#d63031' = 1,
}
export const AllColors: string[] = [
  '#4572a7',
  '#aa4643',
  '#89a54e',
  '#f0d82b',
  '#3d96ae',
  '#80699b',
  '#92a8cd',
  '#4572a7',
  '#aa4643',
  '#89a54e',
  '#f0d82b',
  '#3d96ae',
  '#80699b',
  '#92a8cd',
  '#4572a7',
  '#aa4643',
  '#89a54e',
  '#f0d82b',
  '#3d96ae',
  '#80699b',
  '#92a8cd',
  '#4572a7',
  '#aa4643',
  '#89a54e',
  '#f0d82b',
  '#3d96ae',
  '#80699b',
  '#92a8cd',
  '#4572a7',
  '#aa4643',
  '#89a54e',
  '#f0d82b',
  '#3d96ae',
  '#80699b',
  '#92a8cd',
  '#4572a7',
  '#aa4643',
  '#89a54e',
  '#f0d82b',
  '#3d96ae',
  '#80699b',
  '#92a8cd',
  '#4572a7',
  '#aa4643',
  '#89a54e',
  '#f0d82b',
  '#3d96ae',
  '#80699b',
  '#92a8cd',
  '#4572a7',
  '#aa4643',
  '#89a54e',
  '#f0d82b',
  '#3d96ae',
  '#80699b',
  '#92a8cd',
  '#4572a7',
  '#aa4643',
  '#89a54e',
  '#f0d82b',
  '#3d96ae',
  '#80699b',
  '#92a8cd',
  '#4572a7',
  '#aa4643',
  '#89a54e',
  '#f0d82b',
  '#3d96ae',
  '#80699b',
  '#92a8cd',
  '#4572a7',
  '#aa4643',
  '#89a54e',
  '#f0d82b',
  '#3d96ae',
  '#80699b',
  '#92a8cd',
  '#4572a7',
  '#aa4643',
  '#89a54e',
  '#f0d82b',
  '#3d96ae',
  '#80699b',
  '#92a8cd',
  '#4572a7',
  '#aa4643',
  '#89a54e',
  '#f0d82b',
  '#3d96ae',
  '#80699b',
  '#92a8cd',
  '#4572a7',
  '#aa4643',
  '#89a54e',
  '#f0d82b',
  '#3d96ae',
  '#80699b',
  '#92a8cd',
  '#4572a7',
  '#aa4643',
  '#89a54e',
  '#f0d82b',
  '#3d96ae',
  '#80699b',
  '#92a8cd',
  '#4572a7',
  '#aa4643',
  '#89a54e',
  '#f0d82b',
  '#3d96ae',
  '#80699b',
  '#92a8cd',
  '#4572a7',
  '#aa4643',
  '#89a54e',
  '#f0d82b',
  '#3d96ae',
  '#80699b',
  '#92a8cd',
  '#4572a7',
  '#aa4643',
  '#89a54e',
  '#f0d82b',
  '#3d96ae',
  '#80699b',
  '#92a8cd',
  '#4572a7',
  '#aa4643',
  '#89a54e',
  '#f0d82b',
  '#3d96ae',
  '#80699b',
  '#92a8cd',
  '#4572a7',
  '#aa4643',
  '#89a54e',
  '#f0d82b',
  '#3d96ae',
  '#80699b',
  '#92a8cd',
  '#4572a7',
  '#aa4643',
  '#89a54e',
  '#f0d82b',
  '#3d96ae',
  '#80699b',
  '#92a8cd',
  '#4572a7',
  '#aa4643',
  '#89a54e',
  '#f0d82b',
  '#3d96ae',
  '#80699b',
  '#92a8cd',
  '#4572a7',
  '#aa4643',
  '#89a54e',
  '#f0d82b',
  '#3d96ae',
  '#80699b',
  '#92a8cd',
];
