import { Injectable, signal } from '@angular/core';

export enum DocumentTypeEnum {
  summary = 'summary',
  managementDiary = 'managementDiary',
  engagement = 'engagement',
  feedback = 'feedback',
  evolution = 'evolution',
  analytical = 'analytical',
  wordcloud = 'wordcloud',
  meter = 'meter',
}

@Injectable({
  providedIn: 'root',
})
export class ExportToPdfService {
  protected print = signal<DocumentTypeEnum | null>(null);

  generatePDF(document: DocumentTypeEnum | null): void {
    this.print.set(document);
  }

  getDocumentType(): DocumentTypeEnum | null {
    return this.print();
  }
}
