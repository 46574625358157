import { Component, input, OnChanges, ViewChild } from '@angular/core';

import { ChartConfiguration, ChartOptions } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-bar-chart',
  standalone: true,
  imports: [BaseChartDirective],
  templateUrl: './bar-chart.component.html',
  styleUrl: './bar-chart.component.scss',
})
export class BarChartComponent implements OnChanges {
  public pieChartPlugins = [DataLabelsPlugin];

  public barChartOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          callback: (value) => {
            return value + '%';
          },
        },
      },
    },
    plugins: {
      datalabels: {
        formatter: function (value, context) {
          let stack: string[] = [];
          if (context.dataset.stack) {
            stack = context.dataset.stack.split(',');
          }
          return `${value}%\n ${stack.length ? stack[context.dataIndex] : ''}`;
        },
        color: 'white',
        anchor: 'center',
        align: 'center',
        textAlign: 'center',
        clamp: true,
        font: {
          size: 10,
          weight: 'bolder',
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const value = context.parsed.y;
            return `Value: ${value}%`;
          },
        },
      },
    },
    elements: {
      point: {
        radius: 5,
        hoverRadius: 7,
      },
    },
  };
  // Tipos de gráfico
  barChartLegend = true;

  public barChartData: ChartConfiguration<'bar'>['data'] = {
    labels: [],
    datasets: [],
  };

  data = input.required<ChartConfiguration<'bar'>['data']>();
  text = input.required<string>();

  @ViewChild(BaseChartDirective) chartData?: BaseChartDirective<'bar'>;

  ngOnChanges(): void {
    this.barChartData = this.data();

    this.chartData?.update();
  }
}
