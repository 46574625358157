import { Component, effect, OnDestroy } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { Subject, takeUntil } from 'rxjs';
import { PrintDocumentService } from '../../../../print-documents/print-document.service';
import { BarChartComponent } from '../../../../shared/components/bar-chart/bar-chart.component';
import {
  DocumentTypeEnum,
  ExportToPdfService,
} from '../../../../shared/services/export-to-pdf.service';
import { LoadingService } from '../../../../shared/services/loading.service';
import { ReportFilterService } from '../../../../shared/services/report-filter.service';
import { ReportService } from '../../../../shared/services/report.service';
import { IReportFilter } from '../../../../utils/interfaces/report-filter';

@Component({
  selector: 'app-engagement',
  standalone: true,
  imports: [BarChartComponent],
  templateUrl: './engagement.component.html',
  styles: [],
})
export class EngagementComponent implements OnDestroy {
  filter: IReportFilter | null = null;
  private readonly unsubscribe$ = new Subject();
  data!: ChartConfiguration<'bar'>['data'];

  constructor(
    private readonly reportFilterService: ReportFilterService,
    private readonly reportService: ReportService,
    private readonly loadingService: LoadingService,
    private readonly exportToPdfService: ExportToPdfService,
    private readonly printDocumentService: PrintDocumentService
  ) {
    effect(
      () => {
        this.filter = reportFilterService.getFilter();
        this.getReportData();
      },
      {
        allowSignalWrites: true,
      }
    );

    effect(
      () => {
        this.printDocument(exportToPdfService.getDocumentType());
      },
      {
        allowSignalWrites: true,
      }
    );
  }

  getReportData(): void {
    if (!this.filter) return;

    setTimeout(() => {
      this.loadingService.showLoading();
    }, 0);

    this.reportService
      .getEngagementReport(this.filter)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.data = res.data;

        this.loadingService.closeLoading();
      });
  }

  printDocument(document: DocumentTypeEnum | null): void {
    if (!document || !this.data) return;

    this.printDocumentService.printDocument(DocumentTypeEnum.engagement, {
      data: this.data,
      filter: this.filter,
    });
    this.exportToPdfService.generatePDF(null);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
