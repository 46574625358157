import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

import { debounceTime, Subject, takeUntil } from 'rxjs';

import { MatSelectModule } from '@angular/material/select';
import {
  IPaginationValue,
  PaginationComponent,
} from '../../../shared/components/pagination/pagination.component';
import { TableComponent } from '../../../shared/components/table/table.component';
import { LoadingService } from '../../../shared/services/loading.service';
import { PollService } from '../../../shared/services/poll.service';
import { ReportFilterService } from '../../../shared/services/report-filter.service';
import { ITableHeader } from '../../../utils/interfaces/table-header';

@Component({
  selector: 'app-list-of-reports',
  standalone: true,
  imports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TableComponent,
    PaginationComponent,
    MatSelectModule,
  ],
  templateUrl: './list-of-reports.component.html',
  styleUrl: './list-of-reports.component.scss',
})
export class ListOfReportsComponent implements OnInit, OnDestroy {
  private readonly router = inject(Router);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly loadingService = inject(LoadingService);
  private readonly pollService = inject(PollService);
  private readonly reportFilterService = inject(ReportFilterService);

  private readonly unsubscribe$ = new Subject();

  tableHeader: ITableHeader[] = [
    {
      description: 'Status',
      propertyKey: 'status',
      status: true,
      date: false,
      actions: false,
      filter: false,
    },
    {
      description: 'Pesquisas',
      propertyKey: 'title',
      status: false,
      date: false,
      actions: false,
      filter: false,
    },
    {
      description: 'Empresa',
      propertyKey: 'company',
      status: false,
      date: false,
      actions: false,
      filter: false,
    },
    {
      description: 'N respostas',
      propertyKey: 'submissionCount',
      status: false,
      date: false,
      actions: false,
      filter: false,
    },
    {
      description: 'Última avaliação',
      propertyKey: 'lastSubmission',
      status: false,
      date: true,
      actions: false,
      filter: false,
    },
    {
      description: 'Ações',
      propertyKey: '',
      status: false,
      date: false,
      actions: true,
      filter: false,
    },
  ];

  list: any[] = [];

  countPoll = 0;
  pagination: IPaginationValue = {
    page: 1,
    take: 10,
  };

  searchString = new FormControl<string>('');
  searchStatus = new FormControl<boolean | null>(null);
  actionList: string[] = ['Visualizar'];

  ngOnInit(): void {
    const { count, data } = this.activatedRoute.snapshot.data['polls'];
    this.countPoll = count ?? 0;
    this.list = data;

    this.searchString.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(debounceTime(700))
      .subscribe(() => {
        this.loadingService.showLoading();
        this.getPolls(true);
      });

    this.searchStatus.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.loadingService.showLoading();
        this.getPolls(true);
      });

    this.reportFilterService.setFilter(null);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  onPaginationChange(event: IPaginationValue): void {
    this.pagination = event;

    this.getPolls();
  }

  getPolls(search = false): void {
    this.pollService
      .getPolls(
        search,
        this.searchString.value ?? '',
        this.pagination.page,
        this.pagination.take,
        this.searchStatus.value
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.list = res.data;
        this.countPoll = res.count ?? 0;
        if (search) {
          this.pagination.page = 1;
        }
        this.loadingService.closeLoading();
      });
  }

  selectAction(item: { id: string; func: string }) {
    if (item.func === 'Visualizar') {
      this.router.navigate(['./main/report/data', item.id]);
    }
  }
}
