<div class="page-header">
  <h2>Relatório</h2>

  @if (selectedURL.exportPdf) {
  <button class="btn danger__no-bg" (click)="exportToPdf()">
    Exportar em PDF
  </button>
  } @else {
  <button class="btn danger__no-bg" (click)="exportToExcel()">
    Exportar em Excel
  </button>
  }
</div>

<div class="card">
  <div class="menu">
    @for (url of urls; track $index) {
    <p
      routerLinkActive="active"
      [routerLink]="url.url"
      (click)="selectedURL = url"
    >
      {{ url.title }}
    </p>
    }
  </div>
  <button class="btn danger__no-bg" (click)="openFilterDialog()">
    Filtros
  </button>
</div>

<div id="exportToPDF">
  <router-outlet />
</div>
