<div class="card">
  <p class="title">{{ text() }}</p>
  <hr />

  <div class="chart-wrapper">
    <canvas
      baseChart
      #chartData
      [data]="barChartData"
      [options]="barChartOptions"
      [legend]="barChartLegend"
      [type]="'bar'"
      [plugins]="pieChartPlugins"
    >
    </canvas>
  </div>
</div>
