import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';

import { OrienatationType } from './print-document';
import { PrintDocumentService } from './print-document.service';

@Component({
  selector: 'app-print-document',
  standalone: true,
  imports: [CommonModule, RouterModule],
  template: `<router-outlet (activate)="componentAddEv($event)" />`,
  styles: [],
})
export class PrintDocumentComponent {
  orientation: OrienatationType = 'portrait';
  readonly printDocumentService = inject(PrintDocumentService);

  componentAddEv(template: any) {
    if (!template.activatedRoute && !template.documentPrintingService)
      throw new Error(
        `Para usar o ${template.constructor.name}, o componente deve extender a classe DocumentPrinting e implementar os métodos handleData e reportReadyData.`
      );

    //Orientação da pagina
    const or = template.orientation;
    if (or) this.orientation = or;

    this.checkOrientation();

    /**
     * Verifica se o logo já foi carregado
     * Como não temos um evento de carregamento do logo, chamamos o método diretamente
     */
    this.printDocumentService.onLogoReady();
  }

  checkOrientation() {
    const css = `@media print { @page {size: A4 ${this.orientation};} * {-webkit-print-color-adjust: exact; print-color-adjust: exact;}}`;
    const head = document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    style.appendChild(document.createTextNode(css));
    head.appendChild(style);
  }
}
