import { CommonModule } from '@angular/common';
import { Component, effect, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { CloudData } from 'angular-tag-cloud-module';
import { Subject, takeUntil } from 'rxjs';
import { PrintDocumentService } from '../../../../print-documents/print-document.service';
import { WordCloudComponent } from '../../../../shared/components/word-cloud/word-cloud.component';
import { WordTableComponent } from '../../../../shared/components/word-table/word-table.component';
import {
  DocumentTypeEnum,
  ExportToPdfService,
} from '../../../../shared/services/export-to-pdf.service';
import { LoadingService } from '../../../../shared/services/loading.service';
import { ReportFilterService } from '../../../../shared/services/report-filter.service';
import { ReportService } from '../../../../shared/services/report.service';
import { IPage } from '../../../../utils/interfaces/poll';
import { IReportFilter } from '../../../../utils/interfaces/report-filter';
import { WordFilterComponent } from './word-filter/word-filter.component';

@Component({
  selector: 'app-wordcloud',
  standalone: true,
  imports: [CommonModule, WordCloudComponent, WordTableComponent],
  templateUrl: './wordcloud.component.html',
  styleUrl: './wordcloud.component.scss',
})
export class WordcloudComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$ = new Subject();
  private readonly dialog = inject(MatDialog);
  private readonly activatedRoute = inject(ActivatedRoute);
  filter: IReportFilter | null = null;
  data: CloudData[] = [];

  count = 0;
  title = '';

  constructor(
    private readonly reportFilterService: ReportFilterService,
    private readonly reportService: ReportService,
    private readonly loadingService: LoadingService,
    private readonly exportToPdfService: ExportToPdfService,
    private readonly printDocumentService: PrintDocumentService
  ) {
    effect(
      () => {
        this.printDocument(exportToPdfService.getDocumentType());
      },
      {
        allowSignalWrites: true,
      }
    );
    effect(
      () => {
        this.filter = reportFilterService.getFilter();
        this.getReportData();
      },
      {
        allowSignalWrites: true,
      }
    );
  }

  ngOnInit(): void {
    this.title =
      (
        (this.activatedRoute.snapshot.parent?.data['poll']['data'][
          'pages'
        ] as IPage[]) ?? []
      )
        .find((q) => q.pageNumber === 1)
        ?.questions.find((q) => q.number === 1)?.title ?? '';
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  getReportData(): void {
    if (!this.filter) return;

    setTimeout(() => {
      this.loadingService.showLoading();
    }, 0);

    this.reportService
      .getWordCloudReport(this.filter)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        res.data.wordCloud.sort((a, b) => b.weight - a.weight);
        this.data = [...res.data.wordCloud];

        this.count = 0;
        this.data.forEach((data) => {
          this.count = this.count + data.weight;
        });

        setTimeout(() => {
          this.loadingService.closeLoading();
        }, 750);
      });
  }

  openModal(cloudData: CloudData) {
    this.dialog.open(WordFilterComponent, {
      minWidth: 1024,
      maxWidth: 1024,
      data: { cloudData, filter: this.filter },
    });
  }

  printDocument(document: DocumentTypeEnum | null): void {
    if (!document || !this.data) {
      this.exportToPdfService.generatePDF(null);
      return;
    }

    this.printDocumentService.printDocument(DocumentTypeEnum.wordcloud, {
      data: this.data,
      count: this.count,
      filter: this.filter,
      title: this.title,
    });
    this.exportToPdfService.generatePDF(null);
  }
}
