import { CommonModule } from '@angular/common';
import { Component, input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-gauge-chart',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './gauge-chart.component.html',
  styleUrl: './gauge-chart.component.scss',
})
export class GaugeChartComponent implements OnInit {
  percentage = input.required<number>(); // Valor recebido como entrada
  responses = input.required<string>(); // Texto para exibir
  rotation: string = `rotate(${1.8 * 0 - 180}deg)`; // Rotação inicial da barra

  ngOnInit(): void {
    setTimeout(() => {
      this.updateRotation();
    }, 500); // Atraso para exibir a animação inicial.
  }

  updateRotation(): void {
    // Fórmula para calcular a rotação com base na porcentagem
    this.rotation = `rotate(${1.8 * this.percentage() - 180}deg)`;
  }
}
