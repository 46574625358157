import { Routes } from '@angular/router';
import { getDataWindowResolver } from './get-data-window.resolver';
import { PrintDocumentComponent } from './print-document.component';
import { PrintEngagementComponent } from './print-engagement/print-engagement.component';
import { PrintEvolutionComponent } from './print-evolution/print-evolution.component';
import { PrintMeterComponent } from './print-meter/print-meter.component';
import { PrintSummaryComponent } from './print-summary/print-summary.component';
import { PrintWordCloudComponent } from './print-word-cloud/print-word-cloud.component';

export const PrintDocumentsRoutes: Routes = [
  {
    path: '',
    loadComponent: () => PrintDocumentComponent,
    children: [
      {
        path: 'summary',
        resolve: {
          data: getDataWindowResolver,
        },
        loadComponent: () => PrintSummaryComponent,
      },
      {
        path: 'engagement',
        resolve: {
          data: getDataWindowResolver,
        },
        loadComponent: () => PrintEngagementComponent,
      },
      {
        path: 'evolution',
        resolve: {
          data: getDataWindowResolver,
        },
        loadComponent: () => PrintEvolutionComponent,
      },
      {
        path: 'meter',
        resolve: {
          data: getDataWindowResolver,
        },
        loadComponent: () => PrintMeterComponent,
      },
      {
        path: 'wordcloud',
        resolve: {
          data: getDataWindowResolver,
        },
        loadComponent: () => PrintWordCloudComponent,
      },
    ],
  },
];
