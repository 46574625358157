import {
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import { importProvidersFrom } from '@angular/core';

import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { requestInterceptor } from './app/shared/services/interceptors/request.interceptor';

bootstrapApplication(AppComponent, {
  ...appConfig,
  providers: [
    ...(appConfig.providers || []),
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    provideHttpClient(withFetch(), withInterceptors([requestInterceptor])),
    importProvidersFrom(MatNativeDateModule),
    provideCharts(withDefaultRegisterables()),
  ],
}).catch((err) => console.error(err));
