<p>
  Unidades do filtro: @for (branch of filter.branchesName; track $index) {
  {{ branch }}@if (filter.branchesName && $index < filter.branchesName.length -
  1) {, } }
</p>

<p>
  Período do filtro:
  {{ filter.startDate | date : "dd/MM/yyyy" }} à
  {{ filter.finishDate | date : "dd/MM/yyyy" }}
</p>

@if (data) { @for (page of data.pages; track $index) { @for (question of
page.questions; track $index) {

<div class="card">
  <p class="description">{{ question.title }}</p>
  <hr />
  <div class="amount">
    <img src="assets/img/account_circle.png" alt="account_circle" />
    <p>{{ question.totalResponses }} respostas</p>
  </div>

  <div class="graphics">
    @if (question.satisfactionPercentage) {
    <div class="satisfacao">
      <p>Satisfação</p>
      <h1>{{ question.satisfactionPercentage }}%</h1>
    </div>
    }
    <div class="bar-graphics">
      <app-custom-bar-chart
        [dataType]="
          question.satisfactionPercentage
            ? chartColorsEnum.colorsWeight
            : chartColorsEnum.allColors
        "
        [data]="question.answerDetail"
      />
    </div>
  </div>
</div>

} } @empty {
<div class="no-data">
  <p>Não há dados para exibir</p>
</div>
} } @else {
<div class="no-data">
  <p>Não há dados para exibir</p>
</div>
}
