import { CommonModule } from '@angular/common';
import { Component, effect } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { PrintDocumentService } from '../../../../print-documents/print-document.service';
import { LineChartComponent } from '../../../../shared/components/line-chart/line-chart.component';
import {
  DocumentTypeEnum,
  ExportToPdfService,
} from '../../../../shared/services/export-to-pdf.service';
import { LoadingService } from '../../../../shared/services/loading.service';
import { ReportFilterService } from '../../../../shared/services/report-filter.service';
import { ReportService } from '../../../../shared/services/report.service';
import { IEvolutionReport } from '../../../../utils/interfaces/evolution-report';
import { IReportFilter } from '../../../../utils/interfaces/report-filter';

@Component({
  selector: 'app-evolution',
  standalone: true,
  imports: [CommonModule, LineChartComponent],
  templateUrl: './evolution.component.html',
})
export class EvolutionComponent {
  private readonly unsubscribe$ = new Subject();

  data: IEvolutionReport[] = [];
  filter: IReportFilter | null = null;

  constructor(
    private readonly reportFilterService: ReportFilterService,
    private readonly reportService: ReportService,
    private readonly loadingService: LoadingService,
    private readonly exportToPdfService: ExportToPdfService,
    private readonly printDocumentService: PrintDocumentService
  ) {
    effect(
      () => {
        this.printDocument(exportToPdfService.getDocumentType());
      },
      {
        allowSignalWrites: true,
      }
    );
    effect(
      () => {
        this.filter = reportFilterService.getFilter();
        this.getReportData();
      },
      {
        allowSignalWrites: true,
      }
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  getReportData(): void {
    if (!this.filter) return;

    setTimeout(() => {
      this.loadingService.showLoading();
    }, 0);

    this.reportService
      .getEvolutionReport(this.filter)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.data = res.data;

        setTimeout(() => {
          this.loadingService.closeLoading();
        }, 750);
      });
  }

  printDocument(document: DocumentTypeEnum | null): void {
    if (!document || !this.data) {
      this.exportToPdfService.generatePDF(null);
      return;
    }

    this.printDocumentService.printDocument(DocumentTypeEnum.evolution, {
      data: this.data,
      filter: this.filter,
    });
    this.exportToPdfService.generatePDF(null);
  }
}
