import { Component, input } from '@angular/core';

import { ChartTypeColorsEnum } from '../../../utils/enums/chart-colors.enum';
import { IAnswerDetailSummary } from '../../../utils/interfaces/summary-report';
import {
  AllColors,
  EColorsWeight,
  SingleColor,
} from '../../../utils/type-colors';

@Component({
  selector: 'app-custom-bar-chart',
  standalone: true,
  imports: [],
  templateUrl: './custom-bar-chart.component.html',
  styleUrl: './custom-bar-chart.component.scss',
})
export class CustomBarChartComponent {
  data = input.required<IAnswerDetailSummary[]>();

  dataType = input.required<ChartTypeColorsEnum>();
  chartColorsEnum = ChartTypeColorsEnum;

  singleColor = SingleColor;
  colorsWeight = EColorsWeight;
  allColors = AllColors;
}
