@if(data.length){
<div class="card">
  <div class="card-header">
    <p class="title">{{ title }}</p>
    <p>{{ count }} respostas</p>

    <hr />
  </div>

  <app-word-cloud [data]="data" (onClickEvent)="openModal($event)" />

  <div class="word-table">
    <app-word-table
      [data]="data"
      [count]="count"
      (onClickEvent)="openModal($event)"
    />
  </div>
</div>
} @else {
<div class="empty">
  <p>Não há dados para exibir</p>
</div>
}
