<div class="logo">
  <img src="assets/svg/logo-red.svg" alt="Logo" />
</div>

<form [formGroup]="form">
  <mat-form-field appearance="outline">
    <mat-label>Email</mat-label>
    <input
      matInput
      placeholder="Ex. email@email.com"
      formControlName="email"
      (keyup.enter)="login()"
    />
    @if (form.controls.email.hasError('email') &&
    !form.controls.email.hasError('required')) {
    <mat-error>Por favor insira um endereço de e-mail válido</mat-error>
    } @if (form.controls.email.hasError('required')) {
    <mat-error>Email é obrigatório</mat-error>
    }
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Senha</mat-label>
    <input
      matInput
      [type]="hide ? 'password' : 'text'"
      placeholder="Ex. 123@Mudar"
      formControlName="password"
      (keyup.enter)="login()"
    />
    <mat-icon matSuffix (click)="hide = !hide">{{
      hide ? "visibility_off" : "visibility"
    }}</mat-icon>
    @if (form.controls.password.hasError('required')) {
    <mat-error>Senha é obrigatório</mat-error>
    }
  </mat-form-field>
</form>

<p class="recovery-password" routerLink="../forgot-password">
  <span>Esqueceu a senha?</span>
</p>

<button
  class="btn danger"
  [disabled]="form.invalid"
  (click)="login()"
  (keyup.enter)="login()"
>
  Entrar
</button>

<hr />

<div class="login-office" (click)="loginOffice365()">
  <div>
    <img src="assets/img/microsoft.png" alt="login-office" /> Login com
    Microsoft
  </div>
</div>

<hr />

<div class="help">
  <a href="https://suporte.sapore.com.br/" target="_blank" rel="noopener"
    >Preciso de ajuda</a
  >
</div>
