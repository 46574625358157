<div mat-dialog-title>
  <div class="word-filter-title">
    <p>Pesquisa da palavra chave</p>
    <mat-icon mat-dialog-close (click)="dialogRef.close()">close</mat-icon>
  </div>
  <div class="search">
    <div class="input-icon">
      <input
        type="text"
        class="form-control"
        placeholder="Pesquisar"
        [formControl]="search"
      />
      <img src="assets/svg/search.svg" alt="search" />
    </div>
  </div>
</div>

<mat-dialog-content class="word-filter-content">
  @for (res of wordCloudSearchData; track res) {

  <div class="data">
    <div class="branch">{{ res.code }} {{ res.name }}</div>
    <div class="date">
      {{ res.createdAt | date : "dd/MM/yyyy HH:mm:ss" }}
    </div>
    <div class="satisfaction">{{ res.description }}</div>
    <div class="text">
      {{ res.comment }}
    </div>
  </div>
  } @empty {
  <div class="empty">
    <p>Não há dados para exibir</p>
  </div>
  }
</mat-dialog-content>

<mat-dialog-actions>
  @if (wordCloudSearchData.length) {
  <app-pagination
    [total]="count"
    [value]="pagination"
    [showRowsSelect]="true"
    (onPaginationChange)="onPaginationChange($event)"
  />
  }
</mat-dialog-actions>
