import { inject } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { PrintDocumentService } from './print-document.service';

export abstract class PrintDocument {
  protected printDocumentService = inject(PrintDocumentService);
  protected activatedRoute = inject(ActivatedRoute);

  protected abstract handleData(): void;
  /**
   * Usar o método handleData para manipular os dados recebidos da rota
   *
    protected override handleData() {
      const { data, language } = this.activatedRoute.snapshot.data['data']; //Dados da rota
      this.ondeIraUsarOsDados = data as IDailyReport[]; //Atribuição dos dados a uma variável

      this.setLanguage(language); //Chamada do método setLanguage para definir o idioma da impressão do documento
    }
   */

  protected abstract reportReadyData(): void;
  /**
   * Usar o método reportReadyData para chamar o método onDataReady do PrintDocumentService após os dados estarem prontos
   *
    protected override reportReadyData(): void {
      setTimeout(() => {
        this.PrintDocumentService.onDataReady();
      }, 0);
    }
  */

  changeTitlePage(
    docName: string,
    startDate: string,
    finishDate: string
  ): string {
    return `${docName} ${
      new Date(startDate).getDate() < 9
        ? '0' + new Date(startDate).getDate()
        : new Date(startDate).getDate()
    }-${
      new Date(startDate).getMonth() + 1 < 9
        ? '0' + (new Date(startDate).getMonth() + 1)
        : new Date(startDate).getMonth() + 1
    }-${new Date(startDate).getFullYear()} - ${
      new Date(finishDate).getDate() < 9
        ? '0' + new Date(finishDate).getDate()
        : new Date(finishDate).getDate()
    }-${
      new Date(finishDate).getMonth() + 1 < 9
        ? '0' + (new Date(finishDate).getMonth() + 1)
        : new Date(finishDate).getMonth() + 1
    }-${new Date(finishDate).getFullYear()}`;
  }
}

export type OrienatationType = 'landscape' | 'portrait';
