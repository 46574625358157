import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';

import { ChartConfiguration } from 'chart.js';
import { environment } from '../../../environments/environment';
import { EMonths } from '../../utils/enums/months.enum';
import { IEngagementReportOLD } from '../../utils/interfaces/engagement.report';
import {
  IEvolutionDataReport,
  IEvolutionQuestionReportOLD,
  IEvolutionReport,
  IEvolutionReportOLD,
} from '../../utils/interfaces/evolution-report';
import { IFeedbackReport } from '../../utils/interfaces/feedback-report';
import { IMeter } from '../../utils/interfaces/meter';
import {
  IFeedbackFilter,
  IReportFilter,
} from '../../utils/interfaces/report-filter';
import { IResponse } from '../../utils/interfaces/response';
import { ISummaryReport } from '../../utils/interfaces/summary-report';
import {
  IWordCloud,
  IWordCloudSearch,
} from '../../utils/interfaces/word-cloud';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private readonly URI_API = environment.apiConfig.uri + 'Report';

  constructor(private readonly httpClient: HttpClient) {}

  getSummaryReport(
    filter: IReportFilter
  ): Observable<IResponse<ISummaryReport>> {
    return this.httpClient
      .post<IResponse<ISummaryReport>>(`${this.URI_API}/consolidated`, filter)
      .pipe(
        map((res): IResponse<ISummaryReport> => {
          res.data.pages.sort((a, b) => a.pageNumber - b.pageNumber);
          return res;
        })
      );
  }

  getEngagementReport(
    filter: IReportFilter
  ): Observable<IResponse<ChartConfiguration<'bar'>['data']>> {
    return this.httpClient
      .post<IResponse<IEngagementReportOLD[]>>(
        `${this.URI_API}/engagement`,
        filter
      )
      .pipe(
        map(
          (res): IResponse<ChartConfiguration<'bar'>['data']> =>
            this.transformDataEngagementReport(res)
        )
      );
  }

  getFeedbackReport(
    filter: IFeedbackFilter
  ): Observable<IResponse<IFeedbackReport>> {
    return this.httpClient.post<IResponse<IFeedbackReport>>(
      `${this.URI_API}/feedback`,
      filter
    );
  }

  getFeedbackReportExcel(filter: IReportFilter): Observable<any> {
    return this.httpClient
      .post(`${this.URI_API}/feedback/export`, filter, {
        responseType: 'blob',
      })
      .pipe(
        tap((response) => {
          this.saveDocument(filter, response, 'Feedback');
        })
      );
  }

  getEvolutionReport(
    filter: IReportFilter
  ): Observable<IResponse<IEvolutionReport[]>> {
    return this.httpClient
      .post<IResponse<IEvolutionReportOLD>>(`${this.URI_API}/evolution`, filter)
      .pipe(
        map(
          (res): IResponse<IEvolutionReport[]> =>
            this.transformDataEvolutionReport(filter, res)
        )
      );
  }

  getMeterReport(filter: IReportFilter): Observable<IResponse<IMeter>> {
    return this.httpClient.post<IResponse<IMeter>>(
      `${this.URI_API}/measurement`,
      filter
    );
  }

  getWordCloudReport(filter: IReportFilter): Observable<IResponse<IWordCloud>> {
    return this.httpClient.post<IResponse<IWordCloud>>(
      `${this.URI_API}/wordcloud`,
      filter
    );
  }

  getWordCloudSearchReport(
    filter: IReportFilter,
    searchFilter: {
      searchString: string | null;
      page: number;
      take: number;
      asc: boolean;
      status: boolean;
      branchesId: string[];
    }
  ): Observable<IResponse<IWordCloudSearch>> {
    return this.httpClient.post<IResponse<IWordCloudSearch>>(
      `${this.URI_API}/wordcloud/words?pollId=${filter.pollId}`,
      searchFilter
    );
  }

  /**
   *
   * @param res resposta do relatório de engajamento
   * @returns Resposta do relatório de engajamento com o novo formato de dados
   */
  private transformDataEngagementReport(
    res: IResponse<IEngagementReportOLD[]>
  ): IResponse<ChartConfiguration<'bar'>['data']> {
    const newData: ChartConfiguration<'bar'>['data'] = {
      labels: res.data.map((r) => `${r.month}/${r.year}`),
      datasets: [
        {
          label: 'Avaliações completas',
          data: res.data.map((r) => r.percentCompleted),
          backgroundColor: '#6694FB',
          borderColor: '#6694FB',
          borderWidth: 1,
          stack: res.data.map((r) => r.totalCompleted).toString(),
        },
        {
          label: 'Avaliações incompletas',
          data: res.data.map((r) => r.percentIncomplete),
          backgroundColor: '#BE0013',
          borderColor: '#BE0013',
          borderWidth: 1,
          stack: res.data.map((r) => r.totalIncomplete).toString(),
        },
      ],
    };

    return {
      ...res,
      data: newData,
    };
  }

  /**
   * @param filter filtro do relatório
   * @param res resposta get do relatório de evolução
   * @returns Resposta do relatório de evolução com o novo formato de dados
   * Transforma os dados do relatório de evolução para o novo formato de dados.
   */
  private transformDataEvolutionReport(
    filter: IReportFilter,
    res: IResponse<IEvolutionReportOLD>
  ): IResponse<IEvolutionReport[]> {
    let newData: IEvolutionReport[] = [];

    const allLabels = this.getMonthsInRange(
      new Date(filter.startDate),
      new Date(filter.finishDate)
    );

    newData.push(
      this.transformFirstQuestion(res.data.firstQuestion[0], allLabels)
    );

    res.data.othersQuestions.forEach((OQ) => {
      newData.push(this.transformOthersQuestions(OQ, allLabels));
    });

    const newRes: IResponse<IEvolutionReport[]> = {
      ...res,
      data: newData,
    };

    return newRes;
  }

  /**
   * @param firstQuestion primeira pergunta do relatório de evolução 'Principal'
   * @param labels Array de meses no formato 'Mês/Ano'
   * @returns Dados da primeira pergunta do relatório de evolução
   * Transforma os dados do relatório de evolução para o novo formato de dados.
   */
  private transformFirstQuestion(
    firstQuestion: IEvolutionQuestionReportOLD,
    labels: string[]
  ): IEvolutionReport {
    let dataset: IEvolutionReport = {
      title: firstQuestion.title,
      responses: firstQuestion.countResponses,
      labels: [...labels],
      data: [
        {
          values: [],
          label: 'Satisfação',
        },
      ],
    };

    dataset.labels.forEach((l) => {
      const value = firstQuestion.detailsOthersQuestion.find(
        (F: any) => `${EMonths[F.month]}/${F.year}` === l
      );

      if (value) {
        dataset.data[0].values.push(value.percentage);
      } else {
        dataset.data[0].values.push(0);
      }
    });

    return dataset;
  }

  /**
   * @param OQ Pergunta do relatório de evolução 'Outras'
   * @param allLabels Array de meses no formato 'Mês/Ano'
   * @returns Dados da pergunta do relatório de evolução 'Outras'
   * Transforma os dados do relatório de evolução para o novo formato de dados.
   */
  private transformOthersQuestions(
    OQ: IEvolutionQuestionReportOLD,
    allLabels: string[]
  ): IEvolutionReport {
    let v: IEvolutionReport = {
      title: OQ.title,
      responses: OQ.countResponses,
      labels: [],
      data: [],
    };

    v.labels = allLabels;

    const labels = [
      ...new Set(OQ.detailsOthersQuestion.map((i: any) => i.answer)),
    ];

    v.data = [
      ...labels.map((l) => {
        return { values: [], label: l };
      }),
    ] as IEvolutionDataReport[];

    v.data.forEach((d) => {
      const values = OQ.detailsOthersQuestion.filter(
        (F) => F.answer === d.label
      );

      v.labels.forEach((l) => {
        const value = values.find(
          (F: any) => `${EMonths[F.month]}/${F.year}` === l
        );

        if (value) {
          d.values.push(value.percentage);
        } else {
          d.values.push(0);
        }
      });
    });

    return v;
  }

  /**
   * @param start Data de início
   * @param finish Data final
   * @returns Array de meses no formato 'Mês/Ano' no intervalo de datas
   */
  private getMonthsInRange(start: Date, finish: Date) {
    const monthsInRange = [];

    let current = new Date(start);

    // Enquanto a data de início for antes ou igual à data final
    while (current <= finish) {
      const month = EMonths[current.getMonth() + 1]; // EMonths começa em 1
      const year = current.getFullYear();
      monthsInRange.push(`${month}/${year}`);

      // Avançar para o próximo mês
      current.setMonth(current.getMonth() + 1);
    }

    // Se a data final é no mês de janeiro e não foi adicionada, garantir que ela seja incluída
    if (
      current.getFullYear() === finish.getFullYear() &&
      current.getMonth() === 0
    ) {
      const month = EMonths[current.getMonth() + 1]; // Janeiro/ano
      const year = current.getFullYear();
      monthsInRange.push(`${month}/${year}`);
    }

    return monthsInRange;
  }

  /**
   * @param filter filtro do relatório
   * @param b Blob do relatório
   * @param reportName Nome do relatório
   * @description Salva o documento em formato de Excel
   */
  private saveDocument(
    filter: IReportFilter,
    b: Blob,
    reportName: string
  ): void {
    const blob = new Blob([b], {
      type: 'application/vnd.ms-excel',
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `Relatório de ${reportName} ${
      new Date(filter.startDate).getDate() < 9
        ? '0' + new Date(filter.startDate).getDate()
        : new Date(filter.startDate).getDate()
    }-${
      new Date(filter.startDate).getMonth() + 1 < 9
        ? '0' + (new Date(filter.startDate).getMonth() + 1)
        : new Date(filter.startDate).getMonth() + 1
    }-${new Date(filter.startDate).getFullYear()} - ${
      new Date(filter.finishDate).getDate() < 9
        ? '0' + new Date(filter.finishDate).getDate()
        : new Date(filter.finishDate).getDate()
    }-${
      new Date(filter.finishDate).getMonth() + 1 < 9
        ? '0' + (new Date(filter.finishDate).getMonth() + 1)
        : new Date(filter.finishDate).getMonth() + 1
    }-${new Date(filter.finishDate).getFullYear()}`;

    a.click();
    window.URL.revokeObjectURL(url);
  }
}
