import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PrintDocumentService {
  private uploadedTheLogo = false;
  private uploadedTheTemplate = false;

  printDocument<T>(documentName: string, data: T) {
    const url = `${environment.redirectUri}/print/${documentName}`;
    let w = window.open(url, '_blank', 'location=yes,width=900,height=600');

    /**
     * Para obter os dados no componente que será carregado no popup usar o resolve getDataWindowResolver;
     */
    (<any>w).data = data;
  }

  onDataReady() {
    this.uploadedTheTemplate = true;

    if (this.uploadedTheLogo) this.documentReady();
  }

  onLogoReady() {
    this.uploadedTheLogo = true;

    if (this.uploadedTheTemplate) this.documentReady();
  }

  private documentReady() {
    setTimeout(() => {
      window.print();
      window.close();
      this.uploadedTheLogo = false;
      this.uploadedTheTemplate = false;
    });
  }
}
