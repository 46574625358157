import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  OnInit,
  signal,
} from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { DocumentTypeEnum } from '../../../shared/services/export-to-pdf.service';
import { ReportFilterService } from '../../../shared/services/report-filter.service';
import { IPoll } from '../../../utils/interfaces/poll';
import {
  IReportFilter,
  numbers,
} from '../../../utils/interfaces/report-filter';
import { IUnit } from '../../../utils/interfaces/unit';
import { AssessmentComponent } from './assessment/assessment.component';
import { CrUnitComponent } from './cr-unit/cr-unit.component';
import { DaysWeekComponent } from './days-week/days-week.component';
import { FilterDateComponent } from './filter-date/filter-date.component';
import { PollFilterComponent } from './poll-filter/poll-filter.component';
import { SchedulesComponent } from './schedules/schedules.component';
import { ShiftsComponent } from './shifts/shifts.component';

@Component({
  selector: 'app-report-filters',
  standalone: true,
  templateUrl: './report-filters.component.html',
  styleUrl: './report-filters.component.scss',
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatExpansionModule,
    MatRadioModule,
    AssessmentComponent,
    CrUnitComponent,
    FilterDateComponent,
    DaysWeekComponent,
    ShiftsComponent,
    SchedulesComponent,
    PollFilterComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportFiltersComponent implements OnInit {
  readonly dialogRef = inject(MatDialogRef<ReportFiltersComponent>);
  readonly data = inject<{
    poll: IPoll;
    units: IUnit[];
    url: DocumentTypeEnum;
  }>(MAT_DIALOG_DATA);

  documentTypeEnum = DocumentTypeEnum;

  filter = signal<IReportFilter | null>(null);

  constructor(private readonly reportFilterService: ReportFilterService) {
    effect(
      () => {
        const f = reportFilterService.getFilter();
        if (f) this.checkFilter(f);
      },
      {
        allowSignalWrites: true,
      }
    );
  }

  ngOnInit(): void {
    if (!this.filter()) {
      const f = {
        pollId: this.data.poll.id,
        responseDevice: 0 as numbers,
        responseType: 0 as numbers,
        branchOrUser: 0 as numbers,
        profileId: '',
        branchesId:
          this.data.units.length === 1
            ? [...this.data.units.map((u) => u.id)]
            : [],
        branchesName: [],
        profileUserId: '',
        weekDay: 0 as numbers,
        startDate: '',
        finishDate: '',
        avaiableShifts: [],
        answers: [],
        startTime: '',
        finishTime: '',
      };

      this.filter.set(f);
    }
  }

  checkFilter(f: IReportFilter): void {
    if (this.data.url === DocumentTypeEnum.feedback) {
      f.answers = [];
    }

    this.filter.set(f);
  }

  setFilter(): void {
    this.reportFilterService.setFilter(null);
    if (!this.filter()!.startTime || !this.filter()!.finishTime) {
      this.filter()!.startTime = '';
      this.filter()!.finishTime = '';
    } else {
      this.filter()!.startTime = this.filter()!.startTime + ':00';
      this.filter()!.finishTime = this.filter()!.finishTime + ':00';
    }

    this.reportFilterService.setFilter(this.filter());
    this.dialogRef.close();
  }

  clearFilter(): void {
    const f = {
      pollId: this.data.poll.id,
      responseDevice: 0 as numbers,
      responseType: 0 as numbers,
      branchOrUser: 0 as numbers,
      profileId: '',
      branchesId:
        this.data.units.length === 1
          ? [...this.data.units.map((u) => u.id)]
          : [],
      branchesName: [],
      profileUserId: '',
      weekDay: 0 as numbers,
      startDate: '',
      finishDate: '',
      avaiableShifts: [],
      answers: [],
      startTime: '',
      finishTime: '',
    };

    this.filter.set(f);
  }

  getBranch(): IUnit[] {
    return (
      this.data.units.filter((b) => b.id === this.filter()!.branchesId[0]) || []
    );
  }
}
