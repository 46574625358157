import { Component, input } from '@angular/core';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { IReportFilter } from '../../../../utils/interfaces/report-filter';
import { IUnit } from '../../../../utils/interfaces/unit';

@Component({
  selector: 'app-shifts',
  standalone: true,
  imports: [MatCheckboxModule],
  templateUrl: './shifts.component.html',
  styleUrl: './shifts.component.scss',
})
export class ShiftsComponent {
  filter = input.required<IReportFilter>();
  allUnits = input.required<IUnit[]>();

  handlerShift(shiftID: string): void {
    const shifts = this.filter().avaiableShifts || [];

    if (shifts.includes(shiftID)) {
      this.filter().avaiableShifts = shifts.filter(
        (shift) => shift !== shiftID
      );
    } else {
      this.filter().avaiableShifts = [...shifts, shiftID];
    }
  }
}
