import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnInit, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { CustomBarChartComponent } from '../../shared/components/custom-bar-chart/custom-bar-chart.component';
import { ChartTypeColorsEnum } from '../../utils/enums/chart-colors.enum';
import { IReportFilter } from '../../utils/interfaces/report-filter';
import { ISummaryReport } from '../../utils/interfaces/summary-report';
import { PrintDocument } from '../print-document';

@Component({
  selector: 'app-print-summary',
  standalone: true,
  imports: [CommonModule, CustomBarChartComponent],
  templateUrl: './print-summary.component.html',
  styleUrl: './print-summary.component.scss',
})
export class PrintSummaryComponent
  extends PrintDocument
  implements OnInit, AfterViewInit
{
  chartColorsEnum = ChartTypeColorsEnum;
  orientation = 'portrait';
  data!: ISummaryReport;
  filter!: IReportFilter;

  private readonly titleService = inject(Title);

  ngOnInit(): void {
    this.handleData();
  }

  ngAfterViewInit(): void {
    this.reportReadyData();
  }

  protected override handleData(): void {
    const { data, filter } = this.activatedRoute.snapshot.data['data'];

    this.data = data;
    this.filter = filter;

    this.titleService.setTitle(
      this.changeTitlePage(
        'Relatório de resumo',
        this.filter.startDate,
        this.filter.finishDate
      )
    );
  }

  protected override reportReadyData(): void {
    setTimeout(() => {
      this.printDocumentService.onDataReady();
    }, 0);
  }
}
