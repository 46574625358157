import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from './../../../../shared/services/alert.service';
import { LoadingService } from './../../../../shared/services/loading.service';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { BreadcrumbComponent } from '../../../../shared/components/breadcrumb/breadcrumb.component';
import { ConfirmationModalComponent } from '../../../../shared/components/confirmation-modal/confirmation-modal.component';
import { FileService } from '../../../../shared/services/image-to-base64.service';
import { UnitsService } from '../../../../shared/services/units.service';
import { UserService } from '../../../../shared/services/user.service';
import { IBreadcrumb } from '../../../../utils/interfaces/breadcrumb';
import { IUnitList } from '../../../../utils/interfaces/unit';
import { IUser, IUserForm, IUserUnit } from '../../../../utils/interfaces/user';

@Component({
  selector: 'app-user',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BreadcrumbComponent,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatProgressBarModule,
  ],
  providers: [FileService],
  templateUrl: './user.component.html',
  styleUrl: './user.component.scss',
})
export class UserComponent implements OnInit, OnDestroy {
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly fileService = inject(FileService);
  private readonly dialog = inject(MatDialog);
  private readonly router = inject(Router);
  private readonly unitsService = inject(UnitsService);
  private readonly userService = inject(UserService);
  private readonly loadingService = inject(LoadingService);
  private readonly alertService = inject(AlertService);

  private readonly unsubscribe$ = new Subject();

  breadcrumb: IBreadcrumb[] = [];

  form!: FormGroup<IUserForm>;
  user?: IUser;

  unitSearch = new FormControl<string | null>(null);

  unitsList: IUnitList[] = [];

  loadingUnits = false;

  ngOnInit(): void {
    this.user = this.activatedRoute.snapshot.data['user']?.data;

    this.form = new FormGroup<IUserForm>({
      name: new FormControl<string | null>(
        this.user?.name ?? null,
        Validators.required
      ),
      email: new FormControl<string | null>(
        {
          value: this.user?.email ?? null,
          disabled: this.user?.loginMicrosoft || false,
        },
        [Validators.required, Validators.email]
      ),
      confirmEmail: new FormControl<string | null>(
        {
          value: this.user?.email ?? null,
          disabled: this.user?.loginMicrosoft || false,
        },
        [Validators.required, this.emailMatchValidator.bind(this)]
      ),
      active: new FormControl<boolean>(this.user?.active ?? false),
      roleId: new FormControl<string | null>(
        this.user?.role?.id ?? null,
        Validators.required
      ),
      emailFrequencyList: new FormControl<string[]>(
        this.user?.emailFrequencyList.length
          ? this.user?.emailFrequencyList.map((F) => `${F}`)
          : ['0'],
        Validators.required
      ),
      branchesUser: new FormControl<IUserUnit[] | null>(
        this.user?.branchesByUser?.map((branch) => {
          return { branchId: branch.id!, name: branch.name };
        }) ?? [],
        Validators.required
      ),
      photo: new FormControl<string | null>(this.user?.photo ?? ''),
    });

    if (this.user) {
      this.form.setControl('id', new FormControl<string | null>(this.user.id));

      this.breadcrumb = [
        {
          description: 'Usuários',
          url: 'main/registration/users',
          askBeforeChangingRoute: true,
          textTitle: 'Editar usuário',
          descriptionMsg:
            'Deseja realmente sair da página de edição? As alterações não salvas serão perdidas.',
        },
        {
          description: 'Editar usuário',
          url: '',
        },
      ];
    } else {
      this.breadcrumb = [
        {
          description: 'Usuários',
          url: 'main/registration/users',
          askBeforeChangingRoute: true,
          textTitle: 'Novo usuário',
          descriptionMsg:
            'Deseja realmente sair da página de cadastro? As alterações não salvas serão perdidas.',
        },
        {
          description: 'Novo usuário',
          url: '',
        },
      ];
    }

    this.unitSearch.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(debounceTime(700))
      .subscribe((value) => this.getUnits(value, true));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  selectFrequencyValue(value: '0' | '1' | '2' | '3'): void {
    if (value === '0') {
      this.form.controls.emailFrequencyList.setValue(['0']);
    } else {
      this.form.controls.emailFrequencyList.setValue(
        this.form.controls.emailFrequencyList.value?.filter((v) => v !== '0') ||
          []
      );
    }
  }

  emailMatchValidator(control: FormControl): { [key: string]: boolean } | null {
    const email = this.form?.controls['email'].value;
    return control.value === email ? null : { emailsMismatch: true };
  }

  addUnit(unit: IUnitList): void {
    const units = this.form.controls['branchesUser'].value ?? [];

    if (units.some((u) => u.branchId === unit.id)) {
      this.unitSearch.reset();
      return;
    }

    units.push({ branchId: unit.id, name: unit.name });
    this.form.controls['branchesUser'].setValue(units);

    this.unitSearch.reset();
  }

  deleteUnit(id: string): void {
    const units = this.form.controls['branchesUser'].value ?? [];

    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '500px',
      data: {
        title: 'Excluir unidade',
        description: 'Deseja realmente excluir esta unidade?',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result)
          this.form.controls['branchesUser'].setValue(
            units.filter((unit) => unit.branchId !== id)
          );
      });
  }

  getUnits(filter: string | null, search = false): void {
    if (!filter) {
      this.unitsList = [];
      return;
    }

    this.loadingUnits = true;

    this.unitsService
      .getUnitsFilter(search, filter)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (units) => {
          this.unitsList = units.data;
          this.loadingUnits = false;
        },
      });
  }

  cancel(): void {
    const data: IBreadcrumb = this.breadcrumb[0];

    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '500px',
      data: {
        title: data.textTitle,
        description: data.descriptionMsg,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result) this.router.navigate([data.url]);
      });
  }

  handlerSaveUser(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.loadingService.showLoading();
    if (!this.user) {
      this.createUser(this.form.getRawValue() as IUser);
    } else {
      this.updateUser(this.form.getRawValue() as IUser);
    }
  }

  createUser(user: IUser): void {
    user.emailFrequencyList = user.emailFrequencyList.map((F) => +F);

    this.userService
      .createUser(user)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.alertService.success('Usuário criado com sucesso!');
          this.router.navigate(['main/registration/users']);
        },
      });
  }

  updateUser(user: IUser): void {
    user.emailFrequencyList = user.emailFrequencyList.map((F) => +F);

    this.userService
      .updateUser(user)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.alertService.success('Usuário atualizado com sucesso!');
          this.router.navigate(['main/registration/users']);
        },
      });
  }

  uploadImage(file: File): void {
    this.fileService
      .uploadImage(file)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.form.controls.photo.setValue(res.data);
        this.loadingService.closeLoading();
      });
  }

  resizeImage(event: Event): void {
    const target = event.target as HTMLInputElement;
    const file = (target.files as FileList)[0];

    if (!file) return;

    this.loadingService.showLoading();

    this.fileService
      .resizeImage(file)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((newFile) => {
        this.uploadImage(newFile);
      });
  }
}
