<div class="res">
  @for (res of dataMeter().measurmentDatas ; track $index) {

  <div class="sat">
    <p
      class="percent"
      [ngClass]="{
        ps1: res.weight === 1,
        ps2: res.weight === 2,
        ps3: res.weight === 3,
        ps4: res.weight === 4
      }"
    >
      {{ res.percentage }}%
    </p>
    <p class="img"><img [src]="res.img" alt="IMG" /></p>
    <p class="desc">{{ res.description }}</p>
  </div>

  }
</div>
