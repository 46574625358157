import { Component, input, output } from '@angular/core';
import { CloudData } from 'angular-tag-cloud-module';

@Component({
  selector: 'app-word-table',
  standalone: true,
  imports: [],
  templateUrl: './word-table.component.html',
  styleUrl: './word-table.component.scss',
})
export class WordTableComponent {
  data = input.required<CloudData[]>();
  count = input.required<number>();
  onClickEvent = output<CloudData>();
}
