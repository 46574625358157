<p>
  Unidades do filtro: @for (branch of filter.branchesName; track $index) {
  {{ branch }}@if (filter.branchesName && $index < filter.branchesName.length -
  1) {, } }
</p>

<p>
  Período do filtro:
  {{ filter.startDate | date : "dd/MM/yyyy" }} à
  {{ filter.finishDate | date : "dd/MM/yyyy" }}
</p>

@for (dataset of data; track $index) {
<app-line-chart [labels]="dataset.labels" [datasets]="dataset" />
}
