import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { IPoll } from '../../../../utils/interfaces/poll';
import { IReportFilter } from '../../../../utils/interfaces/report-filter';

@Component({
  selector: 'app-poll-filter',
  standalone: true,
  imports: [CommonModule, MatCheckboxModule],
  templateUrl: './poll-filter.component.html',
  styleUrl: './poll-filter.component.scss',
})
export class PollFilterComponent {
  poll = input.required<IPoll>();
  filter = input.required<IReportFilter>();

  handlerAnswers(answerID: string): void {
    const answers = this.filter().answers || [];

    if (answers.includes(answerID)) {
      this.filter().answers = answers.filter((answer) => answer !== answerID);
    } else {
      this.filter().answers = [...answers, answerID];
    }
  }
}
