import {
  Component,
  input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { ChartConfiguration, ChartOptions } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { BaseChartDirective } from 'ng2-charts';
import { AllColors } from '../../../utils/type-colors';

@Component({
  selector: 'app-line-chart',
  standalone: true,
  imports: [BaseChartDirective],
  templateUrl: './line-chart.component.html',
  styleUrl: './line-chart.component.scss',
})
export class LineChartComponent implements OnChanges {
  colors = AllColors;
  labels = input.required<string[]>();
  datasets = input.required<{
    title: string;
    responses: number;
    data: { values: number[]; label: string }[];
  }>();

  public pieChartPlugins = [DataLabelsPlugin];

  public lineChartData: ChartConfiguration<'line'>['data'] = {
    labels: [],
    datasets: [
      /**
       {
         data: [70, 95, 95, 95, 70, 95, 70],
         label: 'Resposta A',
         fill: false,
         tension: 0.5,
         borderColor: this.colors[0],
         backgroundColor: this.colors[0],
       },
       */
    ],
  };

  @ViewChild(BaseChartDirective) chartData?: BaseChartDirective<'line'>;

  ngOnChanges(changes: SimpleChanges): void {
    this.lineChartData.labels = this.labels();
    this.lineChartData.datasets = this.datasets().data.map((d, index) => {
      return {
        data: d.values,
        label: d.label,
        fill: false,
        tension: 0.5,
        borderColor: this.colors[index],
        backgroundColor: this.colors[index],
      };
    });

    this.chartData?.update();
  }

  public lineChartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          callback: (value) => {
            return value + '%';
          },
        },
      },
    },
    plugins: {
      datalabels: {
        formatter: function (value, context) {
          return `${value}%`;
        },
        color: 'black',
        anchor: 'end',
        align: 'top',
        clamp: true,
        font: {
          size: 10,
          weight: 'bold',
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const value = context.parsed.y;
            return `Value: ${value}%`;
          },
        },
      },
    },
    elements: {
      point: {
        radius: 5,
        hoverRadius: 7,
      },
    },
  };

  public lineChartLegend = true;
}
