<div class="card">
  <p class="title">{{ datasets().title }}</p>

  @if (datasets().responses) {
  <p class="description">
    {{ datasets().responses }} respostas em {{ labels()[0] }} à
    {{ labels()[labels().length - 1] }}
  </p>
  }

  <hr />

  <div class="chart-wrapper">
    <canvas
      #chartData
      baseChart
      [type]="'line'"
      [data]="lineChartData"
      [options]="lineChartOptions"
      [legend]="lineChartLegend"
    >
      <!-- [plugins]="pieChartPlugins" -->
    </canvas>
  </div>
</div>
