import { Component, effect, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { LoadingService } from './../../../shared/services/loading.service';

import { MatDialog } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { AlertService } from '../../../shared/services/alert.service';
import {
  DocumentTypeEnum,
  ExportToPdfService,
} from '../../../shared/services/export-to-pdf.service';
import { ReportFilterService } from '../../../shared/services/report-filter.service';
import { ReportService } from '../../../shared/services/report.service';
import { IPoll } from '../../../utils/interfaces/poll';
import { IReportFilter } from '../../../utils/interfaces/report-filter';
import { IUnit } from '../../../utils/interfaces/unit';
import { ReportFiltersComponent } from '../report-filters/report-filters.component';

@Component({
  selector: 'app-report-data',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './report-data.component.html',
  styleUrl: './report-data.component.scss',
})
export class ReportDataComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$ = new Subject();
  private readonly exportToPdfService = inject(ExportToPdfService);
  private readonly dialog = inject(MatDialog);
  private readonly activatedRoute = inject(ActivatedRoute);

  poll!: IPoll;
  units: IUnit[] = [];

  urls = [
    {
      title: 'Resumo',
      url: DocumentTypeEnum.summary,
      exportPdf: true,
    },
    {
      title: 'Diário gerencial',
      url: DocumentTypeEnum.managementDiary,
      exportPdf: true,
    },
    {
      title: 'Engajamento',
      url: DocumentTypeEnum.engagement,
      exportPdf: true,
    },
    {
      title: 'Feedback',
      url: DocumentTypeEnum.feedback,
      exportPdf: false,
    },
    {
      title: 'Evolução',
      url: DocumentTypeEnum.evolution,
      exportPdf: true,
    },
    {
      title: 'Analítico',
      url: DocumentTypeEnum.analytical,
      exportPdf: true,
    },
    {
      title: 'Wordcloud',
      url: DocumentTypeEnum.wordcloud,
      exportPdf: true,
    },
    {
      title: 'Medidor',
      url: DocumentTypeEnum.meter,
      exportPdf: true,
    },
  ];

  selectedURL = this.urls[0];

  filter: IReportFilter | null = null;

  constructor(
    private readonly reportFilterService: ReportFilterService,
    private readonly alertService: AlertService,
    private readonly reportService: ReportService,
    private readonly loadingService: LoadingService
  ) {
    effect(
      () => {
        this.filter = reportFilterService.getFilter();
      },
      {
        allowSignalWrites: true,
      }
    );
  }

  ngOnInit(): void {
    this.poll = this.activatedRoute.snapshot.data['poll']?.data;
    this.units = this.activatedRoute.snapshot.data['unitUserPoll']?.data;

    const url = window.location.href;
    const lastSegment = url.split('/').pop();

    if (lastSegment) {
      this.selectedURL =
        this.urls.find((url) => url.url === lastSegment) || this.urls[0];
    }

    this.openFilterDialog();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  openFilterDialog(): void {
    this.dialog.open(ReportFiltersComponent, {
      minWidth: 865,
      maxWidth: 865,
      data: {
        poll: this.poll,
        units: this.units,
        url: this.selectedURL.url,
      },
    });
  }

  exportToPdf(): void {
    if (!this.filter) {
      this.alertService.warning(
        'Não foi possível gerar o relatório, filtro não selecionado.'
      );
      return;
    }
    this.exportToPdfService.generatePDF(this.selectedURL.url);
  }

  exportToExcel(): void {
    if (!this.filter) {
      this.alertService.warning(
        'Não foi possível gerar o relatório, filtro não selecionado.'
      );
      return;
    }

    this.loadingService.showLoading();

    if (this.selectedURL.url === DocumentTypeEnum.feedback) {
      this.reportService
        .getFeedbackReportExcel(this.filter)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => this.loadingService.closeLoading());
    }
  }
}
