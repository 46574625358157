<p>
  Unidades do filtro: @for (branch of filter.branchesName; track $index) {
  {{ branch }}@if (filter.branchesName && $index < filter.branchesName.length -
  1) {, } }
</p>

<p>
  Período do filtro:
  {{ filter.startDate | date : "dd/MM/yyyy" }} à
  {{ filter.finishDate | date : "dd/MM/yyyy" }}
</p>

@if(data.length){
<div class="card">
  <div class="card-header">
    <p class="title">{{ title }}</p>
    <p>{{ count }} respostas</p>

    <hr />
  </div>

  <app-word-cloud [data]="data" />

  <div class="word-table">
    <app-word-table [data]="data" [count]="count" />
  </div>
</div>
} @else {
<div class="empty">
  <p>Não há dados para exibir</p>
</div>
}
