import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingService } from './../../../../../shared/services/loading.service';

import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { CloudData } from 'angular-tag-cloud-module';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import {
  IPaginationValue,
  PaginationComponent,
} from '../../../../../shared/components/pagination/pagination.component';
import { ReportService } from '../../../../../shared/services/report.service';
import { IReportFilter } from '../../../../../utils/interfaces/report-filter';
import { IWordCloudSearchData } from '../../../../../utils/interfaces/word-cloud';

@Component({
  selector: 'app-word-filter',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    MatIconModule,
    PaginationComponent,
  ],
  templateUrl: './word-filter.component.html',
  styleUrl: './word-filter.component.scss',
})
export class WordFilterComponent implements OnInit, OnDestroy {
  readonly dialogRef = inject(MatDialogRef<WordFilterComponent>);
  readonly loadingService = inject(LoadingService);
  readonly reportService = inject(ReportService);
  readonly data = inject<{ cloudData: CloudData; filter: IReportFilter }>(
    MAT_DIALOG_DATA
  );
  private readonly unsubscribe$ = new Subject();

  search = new FormControl('');

  count = 0;
  pagination: IPaginationValue = {
    page: 1,
    take: 10,
  };

  wordCloudSearchData: IWordCloudSearchData[] = [];

  ngOnInit(): void {
    this.loadingService.showLoading();

    this.search.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(debounceTime(750))
      .subscribe((value) => this.getWordData(value));

    this.search.setValue(this.data.cloudData.text);
  }

  getWordData(searchString: string | null): void {
    this.loadingService.showLoading();

    this.reportService
      .getWordCloudSearchReport(this.data.filter, {
        searchString: searchString,
        page: this.pagination.page,
        take: this.pagination.take,
        branchesId: this.data.filter.branchesId,
        asc: true,
        status: true,
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.wordCloudSearchData = [...res.data.wordData].sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );

        this.count = res.count ?? 0;
        this.loadingService.closeLoading();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  onPaginationChange(event: IPaginationValue): void {
    this.pagination = event;
  }
}
